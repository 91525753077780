import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  styled,
  type CardProps,
} from '@mui/material';
import { Provider } from '@repo/api-gw-sdk';

import OptionMenu from '@core/components/option-menu';

import { Panels } from '@/components/panels/panels';
import { useEnvironment } from '@/contexts/useEnvironment';
import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import { PanelSize } from '@/contexts/workspaceContext';
import { CloudProviders } from '@/data/inventory/data';
import type { BackupVault } from '@/data/vaults/backupVault';

interface BackupVaultCardProps {
  data?: BackupVault;
  loading?: boolean;
  style?: object;
  editable?: boolean;
  onChange?: () => void;
  onClick?: (vault: BackupVault) => void;
  clickable?: boolean;
  selected?: boolean;
}

interface VaultCardProps extends CardProps {
  clickable: boolean;
  selected?: boolean;
}

const CustomCard = ({ clickable, ...props }: VaultCardProps) => (
  <Card {...props}>{props.children}</Card>
);

const VaultCard = styled(CustomCard)(({ theme, clickable, selected }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'left',
  minWidth: '300px',
  maxWidth: '420px',
  minHeight: '172px',
  boxShadow: 'none',
  padding: '24px',
  border: selected
    ? `3px solid ${theme.palette.mode === 'dark' ? '#fff' : '#000'}`
    : clickable
      ? '1px solid transparent'
      : 'none',
  borderRadius: '16px',
  '& .MuiTypography-root': {
    fontSize: '12px',
  },
  '& .MuiCardHeader-root': {
    padding: 0,
    marginBottom: '12px',
  },
  '& .MuiCardContent-root': {
    padding: 0,
  },
  cursor: clickable ? 'pointer' : 'default',
  '&:hover': {
    border: selected
      ? `3px solid ${theme.palette.mode === 'dark' ? '#fff' : '#000'}`
      : clickable
        ? '1px solid #727283'
        : 'none',
  },
}));

const VaultTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  color: theme.palette.text.secondary,
  padding: '4px 10px 4px 0px',
}));

const VaultTablePropCell = styled(VaultTableCell)(() => {
  return {
    minWidth: '120px',

    '& p': {
      fontWeight: '500',
    },
  };
});

export default function BackupVaultCard(props: BackupVaultCardProps) {
  const { isAuthorized } = useRoles();
  const { rightPanel } = useWorkspace();
  const { setIsOpen, setComponent } = rightPanel;
  const { isDev, isDemo } = useEnvironment();
  function editVault() {
    if (!props.data) {
      return;
    }

    setComponent({
      panel: Panels.EditBackupVault,
      props: {
        entity: props.data,
        onChange: () => {
          props.onChange?.();
        },
      },
    });
    setIsOpen(true, PanelSize.Small);
  }

  const testid = props.loading
    ? 'loading-vault-card'
    : props.selected
      ? 'backup-vault-card-selected'
      : 'backup-vault-card';

  return (
    <VaultCard
      selected={props.selected}
      clickable={props.onClick !== undefined}
      onClick={() => props.onClick?.(props.data!)}
      data-testid={testid}
      sx={{
        backgroundColor: props?.data?.backgroundColor,
        ...props.style,
      }}
    >
      {props.loading && (
        <>
          <CardHeader component={Skeleton} />
          <CardContent>
            <table className='w-full table-fixed'>
              <tbody>
                {[...Array(4)].map((_, index) => (
                  <tr key={index}>
                    <td className='px-[5px] pt-[5px] pl-0'>
                      <Skeleton />
                    </td>
                    <td className='pl-[5px] pt-[5px] pr-0'>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </>
      )}
      {!props.loading && props.data && (
        <>
          <CardHeader
            sx={{ paddingBottom: '5px' }}
            title={props.data.name}
            titleTypographyProps={{
              variant: 'body1',
              sx: {
                fontSize: '12px',
                fontWeight: 'bold',
              },
            }}
            action={
              props.editable !== false && (
                <OptionMenu
                  icon='material-symbols-settings-outline'
                  options={[
                    {
                      testId: 'edit-vault',
                      text: 'Edit',
                      menuItemProps: {
                        disabled: !isAuthorized('update:vaults'),
                        onClick: editVault,
                      },
                    },
                  ]}
                />
              )
            }
          />
          <CardContent>
            <TableContainer>
              <Table className='table-fixed'>
                <tbody>
                  <TableRow>
                    <VaultTablePropCell>
                      <Typography>Cloud provider</Typography>
                    </VaultTablePropCell>
                    <VaultTableCell>
                      <Typography>
                        {CloudProviders[props.data.cloudProvider]?.displayName}
                      </Typography>
                    </VaultTableCell>
                  </TableRow>
                  <TableRow>
                    <VaultTablePropCell>
                      <Typography>Account ID</Typography>
                    </VaultTablePropCell>
                    <VaultTableCell>
                      <Tooltip title={props.data.cloudAccountId}>
                        <Typography className='truncate'>
                          {props.data.cloudAccountId}
                        </Typography>
                      </Tooltip>
                    </VaultTableCell>
                  </TableRow>
                  <TableRow>
                    <VaultTablePropCell>
                      <Typography>Region</Typography>
                    </VaultTablePropCell>
                    <VaultTableCell>
                      <Typography>{props.data.region}</Typography>
                    </VaultTableCell>
                  </TableRow>
                  {(isDev || isDemo) &&
                    props.data.cloudProvider === Provider.Aws && (
                      <TableRow>
                        <VaultTablePropCell>
                          <Typography>Object lock</Typography>
                        </VaultTablePropCell>
                        <VaultTableCell>
                          <Typography>
                            {props.data.objectLock ? '✓' : '✗'}
                          </Typography>
                        </VaultTableCell>
                      </TableRow>
                    )}
                </tbody>
              </Table>
            </TableContainer>
          </CardContent>
        </>
      )}
    </VaultCard>
  );
}

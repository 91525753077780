import { Box, styled } from '@mui/material';

import { useFeatureFlags } from '@/contexts/useFeatureFlags';

export const SnapshotIndicator = (props: {
  backgroundColor: string | null;
  isPartial?: boolean;
  sx?: object;
}) => {
  const { partialSnapshotsIndication } = useFeatureFlags();

  return partialSnapshotsIndication && props.isPartial ? (
    <PartialSnapshotIndicator
      backgroundColor={props.backgroundColor}
      sx={props.sx}
    />
  ) : (
    <FullSnapshotIndicator
      backgroundColor={props.backgroundColor}
      sx={props.sx}
    />
  );
};

const FullSnapshotIndicator = styled('div')(
  (props: { backgroundColor: string | null; sx?: object }) => ({
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
    minWidth: '12px',
    minHeight: '12px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: props.backgroundColor || 'gray',
    backgroundColor: props.backgroundColor || 'transparent',
    ...props.sx,
  })
);

const PartialSnapshotIndicator = (props: {
  backgroundColor: string | null;
  sx?: object;
}) => {
  return (
    <Box
      sx={{
        height: '12px',
        width: '12px',
        borderRadius: '50%',
        display: 'inline-block',
        minWidth: '12px',
        minHeight: '12px',
        border: `2px solid ${props.backgroundColor}`,
        overflow: 'hidden',
        ...props.sx,
      }}
    >
      <Box
        sx={{
          marginTop: '-1.5px',
          marginLeft: '-3px',
          height: '5px',
          width: '12px',
          transform: 'rotate(-45deg)',
          backgroundColor: props.backgroundColor,
        }}
      ></Box>
    </Box>
  );
};
